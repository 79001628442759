import React from "react";
import "../Styles/privacy.css";
import { useNavigate } from "react-router-dom";
import { MdArrowBackIosNew } from "react-icons/md";

function PrivacyPolicy() {
    const navigate = useNavigate()
  return (
    <div className="privacyMain">
    <div className="backbtn">
    <MdArrowBackIosNew
        onClick={() => navigate("/home")}
        style={{
          color: "black",
          fontSize: "26px",
          marginLeft: "5px",
          marginTop: "5px",
        }}
      />
    </div>
      <div className="privacypolicy">
        <h6>PRIVACY POLICY</h6>
        <br />
        <b>
          This document is an electronic record in terms of Information
          Technology Act, 2000 and rules there under as applicable and the
          amended provisions pertaining to electronic records in various
          statutes as amended by the Information Technology Act, 2000. This
          electronic record is generated by a com puter system and does not
          require any physical or digital signatures.
        </b>
        <p className="mt-1">
          For the purpose of these Privacy policies, wherever the context so
          requires <b>"You"</b> or <b>"User"</b> shall mean any natural or legal
          person who visits our platform, either just for the purpose of
          browsing{" "}
          <a href="http://www.dailydish.in" target="_blank">
            <u>www.dailydish.in</u>
          </a>{" "}
          (our <b>“Website”</b>) website or the <b>Chef Studio innovations</b>,{" "}
          <b>Partnership firm</b> to buy our product. The term "<b>We"</b>,{" "}
          <b>"Us"</b>, <b>"Our"</b> shall mean Chef Studio Innvations
          (Partnership firm).
        </p>
        <br />
        <div className="leftHead">Definition</div>
        <p>
          “App(s)” shall mean applications for mobile and includes Dailydish
          App.
        </p>
        <p>
          “Device(s)” shall mean a desktop computer, laptop computer, tablet,
          mobile phone, smart watch or such other electronic equipment.
        </p>
        <p>
          “Force Majeure Event” shall mean any event that is beyond our
          reasonable control and shall include, without limitation, sabotage,
          fire, flood, explosion, acts of God, civil commotion, strikes or
          industrial action or any kind, riots, insurrection, war, acts of
          government, computer hacking, unauthorized access to your mobile or
          storage device, crashes, breach of security and encryption.
        </p>
        <p>
          “Personal Information” refers to any information that identifies or
          can be used to identify, contact or locate the person, to whom such
          information pertains including, but not limited to, name, address,
          phone number and email address disclosed by you in relation to the
          services available on the Platform.
        </p>
        <br />
        <p>
          “Sensitive personal data or information” consists of information
          relating to the following:
        </p>
        <ol>
          <li>Passwords</li>
          <li>
            Financial information such as bank account or credit card or debit
            card or other payment instrument details
          </li>
          <li>physical, physiological and mental health condition</li>
          <li>sexual orientation</li>
          <li>medical records and history</li>
          <li>Biometric information</li>
          <li>
            any detail relating to the above clauses as provided to body
            corporate for providing service; and
          </li>
          <li>
            any of the information received under above clauses by body
            corporate for processing, stored or processed under lawful contract
            or otherwise
          </li>
        </ol>
        <p>
          “Third Party” refers to any person or entity other than you or us.
        </p>
        <br />
        <h6>SECTION 1 - WHAT INFORMATION DO WE COLLECT</h6>
        <br />
        <p>
          <b>Personal Data:</b> We collect personal data from you when you
          voluntarily provide such information, such as when you contact us to
          place order for our products or to inquire about any information,
          respond to one of our surveys.
        </p>
        <p>
          <b>
            Personal Information provided by you in relation to the use of the
            Platform:
          </b>{" "}
          In order for us to enable successful delivery of your order to you, we
          collect your Personal Information as may be required for the purposes
          connected thereto when you place an order on the Platform.
        </p>
        <p>
          We may be required to disclose such Personal Information to the Third
          Parties solely in connection with purchase, sale and delivery of your
          order in accordance with the terms and conditions stipulated by such
          Third Party.
        </p>
        <p>
          We use the details provided by you during check out to ensure that
          orders are successfully delivered on time, and to keep you informed on
          the status of your order.
        </p>
        <p>
          <b>
            {" "}
            Personal Information provided by you in relation to the registration
            process:
          </b>
          In order to provide you a safe, efficient, smooth and customized
          experience and in order to take advantage of certain features
          available on the Platform including, but not limited to, public
          forums, accessing or uploading reviews or taking part in surveys, you
          will be asked to complete an online registration form which will
          provide us with Personal Information. In the event you register with
          us, you will also be asked to choose a user identity and password in
          order to identify yourself during future uses of the Platform.
        </p>
        <p>
          You can also register on the Platform by logging into online accounts
          you may have with Third Party social networking sites (“SNS”) (e.g.
          Facebook, Twitter); each such account, a “Third Party Account”, via
          our Platform as described below. As part of the functionality of the
          Platform, you may link your account with Third Party Accounts, by
          either: (i) providing your Third Party Account login information to us
          through the Platform; or (ii) allowing us to access your Third Party
          Account, as is permitted under the applicable terms and conditions
          that govern your use of each Third Party Account. You represent that
          you are entitled to disclose your Third Party Account login
          information to us and/or grant us access to your Third Party Account
          (including, but not limited to, for the purposes described herein),
          without breach by you of any of the terms and conditions that govern
          your use of the applicable Third Party Account and without obligating
          us to pay any fees or making us subject to any usage limitations
          imposed by the applicable SNS. If you decide to register by logging
          into a Third Party Account via our Platform, we will obtain the
          Personal Information you have provided to the applicable SNS (such as
          your “real” name, email address, profile picture, names of SNS
          friends, names of SNS groups to which you belong, other information
          you make publicly available via the applicable SNS and/or other
          information you authorize us to access by authorizing the SNS to
          provide such information) from your Third Party Accounts and use that
          information to create your account and profile page and you will
          become a member. Depending on the Third Party Accounts you choose and
          subject to the privacy settings that you have set in such Third Party
          Accounts, you understand that by granting us access to the Third Party
          Accounts, we will access, make available and store (if applicable and
          as permitted by the SNS and authorized by you) the information in your
          Third Party Accounts so that it is available on and through your
          account on the Platform. If there is information about your “friends”
          or people with whom you are associated in your Third Party Account,
          the information we obtain about those “friends” or people with whom
          you are associated, may also depend on the privacy settings such
          people have with the applicable SNS.
        </p>
        <p>
          We also collect certain additional information that is not Personal
          Information which you provide as part of registration and the
          administration and personalization of your account profile. When you
          use our Services, we collect information about you in the following
          general categories.
        </p>
        <p>
          If you permit the App to access the address book on your Device
          through the permission system used by your Devise platform, we may
          access and store names, contact and messages information from your
          address book to facilitate any form of interactions through our
          Services and for other purposes specified in this Privacy Policy or
          the Terms of Use.
        </p>
        <p>
          In connection with facilitating this service, we receive call data,
          including the date and time of the call or SMS message, the parties’
          phone numbers, and the content of the SMS message, etc.
        </p>
        <p>
          <b> Collection of Sensitive personal data or information:</b> We do
          not collect, store or process Sensitive personal data or information
          as part of our services on our Platform.
        </p>
        <p>
          <b>Others:</b> In addition to the cookies, we may also collect
          information to enable us to understand you better so that we can
          improve (i) your user experience; (ii) to assist our customer service
          and technical support personnel; and (iii) put processes in place to
          prevent fraud and unlawful use. In an effort to make the Platform
          effective and improve the Platform, certain information may be
          collected each time you access the Platform. Such information may be
          saved in server logs in an encrypted form which may not identify you
          personally. Such information or data may include, but shall not be
          limited to, IP address, your server details, duration of your visit,
          date, time or purpose of your visit. In addition to cookies we use web
          beacons, ad tags, pixels to advertise as part of the Platform. The
          abovementioned data may be used by us and may be shared with our
          sponsors, investors, advertisers, developers, strategic business
          partners or other associates to enhance and grow the user experience
          in relation to the Platform.
        </p>
        <p>
          <b>URLs:</b> In general, You can browse our Website/App without
          telling Us who You are or revealing any personal information about
          yourself. Once You give us your personal information, you are not
          anonymous to us. Where possible, we indicate which fields are required
          and which fields are optional. You always have the option to not
          provide information for the fields that are optional. We may
          automatically track certain information about You based uponyour
          behaviour on Our Website/App. We use this information to do internal
          research on Our users' demographics, interests, and behaviour to
          better understand, protect and serve Our users. This information is
          compiled and analysed on an aggregated basis. This information may
          include the URL that you just came from (whether this URL is on our
          Website or not), which URL You next go to (whether this URL is on Our
          Website or not), your browser information, and your IP address.
        </p>
        <p>
          <b>Cookies:</b> We use data collection devices such as "cookies" on
          certain pages of the Website to help analyse our web page flow,
          measure promotional effectiveness, and promote trust and safety.
          "Cookies" are small files placed on your hard drive that assist us in
          providing our services. We offer certain features that are only
          available through the use of a "cookie". Most cookies are "session
          cookies," meaning that they are automatically deleted from your hard
          drive at the end of a session. You are always free to decline our
          cookies if your browser permits. Additionally, you may encounter
          "cookies" or other similar devices on certain pages of the Website
          that are placed by third parties. We do not control the use of cookies
          by third parties.
        </p>
        <p>
          The cookies used by us are listed as follows for your information to
          opt-out of cookies or not.
        </p>
        <ul>
          <li>
            _session_id, unique token, sessional, Allows Dailydish to store
            information about your session (referrer, landing page, etc)
          </li>
          <li>
            provider’s internal stats tracker to record the number of visits
          </li>
          <li>
            _secure_session_id, unique token, sessional storefront_digest,
            unique token, indefinite If the shop has a password, this is used to
            determine if the current visitor has access.
          </li>
          <li>Your payment details.</li>
          <li>Your IP address and</li>
          <li>
            Any other personal information which you give us in connection with
            the Services.
          </li>
          <li>
            Wherever We collect personal data we make an effort to provide a
            link to this Privacy Policy.
          </li>
          <li>
            If You send us personal correspondence, such as emails or letters,
            we may collect such information into a file specific to You.
          </li>
        </ul>

        <p>
          <b>Payment Information:</b> If You transact with Us, We collect some
          additional information, such as a billing address, a credit / debit
          card number and a credit / debit card expiration date and/ or other
          payment instrument details and tracking information from cheques or
          money orders.
        </p>
        <br />
        <h6>SECTION 2: WHAT DO WE DO WITH YOUR INFORMATION</h6>
        <br />
        <p>
          2.1 We use personal information to provide the delivery of our
          products to you. To the extent we use your personal information to
          market to You, We will provide you the ability to opt-out of such
          uses. We use your personal information to resolve disputes;
          troubleshoot problems; help promote a safe transaction; collect money;
          measure consumer interest in Our products, inform You about online and
          offline offers, products and updates; customize your experience;
          detect and protect Us against error, fraud and other criminal
          activity; enforce Our terms and conditions; and as otherwise described
          to You at the time of collection
        </p>
        <p>
          2.2 In Our efforts to continually improve Our product offerings, We
          collect and analyse demographic and profile data about Our Users'
          activity on Our Website. We identify and use your IP address to help
          diagnose problems with our server, and to administer Our Website. Your
          IP address is also used to help identify You and to gather broad
          demographic information.
        </p>
        <br />
        <h6>SECTION 3 – CONSENT</h6>
        <p>
          3.1 When You provide Us with personal information to complete a
          transaction, verify your credit card, place an order, arrange for a
          delivery or return a purchase, it is deemed that You have consented to
          the use of your personal information by Us to carry out the
          transaction as requested.
        </p>
        <p>
          3.2 In the course of business, if your personal information for any
          secondary reason, We shall specify the reason for requiring such
          information. Upon such request, You shall have the option to refrain
          from revealing such information to Us.
        </p>
        <p>
          3.3 If after You consent, either impliedly or expressly, for your
          personal information to be used by Us, You change your mind, You may
          withdraw your consent for Us to contact You, for the continued
          collection, use or disclosure of your information, at any time. Such
          withdrawal of consent shall be communicated to Us either through an
          email at <u>orders@dailydish.in</u> or by contacting Us at our at our
          corporate office.
        </p>
        <br />
        <h6>SECTION 4 – DISCLOSURE OF PERSONAL INFORMATION</h6>
        <p>
          4.1 We may share personal information with our other corporate
          entities and affiliates to help detect and prevent identity theft,
          fraud and other potentially illegal acts; correlate related or
          multiple accounts to prevent abuse of Our Website; and to facilitate
          joint or co-branded services where such services are provided by more
          than one corporate entity. Those entities and affiliates may not
          market to You as a result of such sharing unless You explicitly
          opt-in.
        </p>
        <p>
          4.2 We may disclose personal information of You if required to do so
          by law or in the good faith belief that such disclosure is reasonably
          necessary to respond to subpoenas, court orders, or other legal
          process. We may disclose personal information to law enforcement
          offices, third party rights owners, or others in the good faith belief
          that such disclosure is reasonably necessary to enforce our Privacy
          Policy; respond to claims that an advertisement, posting or other
          content violates the rights of a third party; or protect the rights,
          property or personal safety of our Users or the general public
        </p>
        <br />
        <h6>SECTION 5 – DAILYDISH.IN</h6>
        <p>
          5.1 All direct payment gateways adhere to the standards set by PCI-DSS
          as managed by the PCI Security Standards Council, which is a joint
          effort of brands like Visa, MasterCard, American Express and Discover.
        </p>
        <p>
          5.2 PCI-DSS requirements help ensure the secure handling of credit
          card information by Our store and its service providers
        </p>
        <p>
          5.3 For more insight, you may also want to read Dailydish.in Terms of
          Service here or Privacy Statement here.
        </p>
        <br />
        <h6>SECTION 6 - THIRD-PARTY SERVICES</h6>
        <p>
          6.1 We may engage the services of third parties to help us serve Our
          customers better. In general, the third-party services providers
          engaged by Us will only collect, use and disclose your information to
          the extent necessary to allow them to perform the services they
          provide to Us.
        </p>
        <p>
          6.2 However, certain third-party service providers, such as payment
          gateways and other payment transaction processors, have their own
          privacy policies in respect to the information We are required to
          provide to them for your purchase-related transactions and
        </p>
        <p>
          We recommend that You read their privacy policies so You can
          understand the manner in which your personal information will be
          handled by these third party service providers.
        </p>
        <p>
          6.3 In particular, remember that certain providers may be located in
          or have facilities that are located a different jurisdiction than
          either you or us. So if you elect to proceed with a transaction that
          involves the services of a third-party service provider, then your
          information may become subject to the laws of the jurisdiction(s) in
          which that service provider or its facilities are located.
        </p>
        <p>
          6.4 Once you leave Our website/App or are redirected to a third-party
          website or application, You are no longer governed by this Privacy
          Policy or our website’s Terms of Service.
        </p>
        <p>
          6.5 When you click on third party links on Our Website, We are not
          responsible for the privacy practices of other sites and for the loss
          or consequential damage that may be caused to you and encourage You to
          read their privacy statements.
        </p>
        <br />
        <h6>SECTION 7 – SECURITY</h6>
        <p>
          7.1 To protect your personal information, we take reasonable
          precautions and follow industry best practices to make sure it is not
          inappropriately lost, misused, accessed, disclosed, altered or destroy
        </p>
        <p>
          7.2 If you provide Us with your credit card information, the
          information is encrypted using secure socket layer technology (SSL)
          and stored with a AES-256 encryption. Although, no method of
          transmission over the Internet or electronic storage is 100% secure,
          we follow all PCI-DSS requirements and implement additional generally
          accepted industry standards.
        </p>
        <br />
        <h6>SECTION 8 – AGE OF CONSENT</h6>
        <p>
          8.1 By using this site, You represent that You are at least the age of
          majority in your state or province of residence and you have given us
          your consent to allow any of your minor dependents to use this site.
        </p>
        <br />
        <h6>SECTION 9 – CHANGES TO THIS PRIVACY POLICY</h6>
        <p>
          9.1 We reserve the right to modify this privacy policy at any time, so
          please review it frequently. Changes and clarifications will take
          effect immediately upon their posting on the Website. If We make
          material changes to this policy, We will notify You here that it has
          been updated, so that You are aware of what information We collect,
          how we use it, and under what circumstances, if any, We use and/or
          disclose it.
        </p>
        <p>
          9.2 If our Website is acquired or merged with another company, your
          information may be transferred to the new owners so that we may
          continue to sell products to You.
        </p>
        <br />
        <h6>SECTION 10 – ACCOUNT DELETION</h6>
        <p>
          10.1 - Customer willing to delete his/her account can send a mail to
          orders@dailydish.in
        </p>
        <p>
          10.2 - Customer profile will be deleted from our database, which also
          includes, Name, email, DOB, Address, Orders, Payment details.
        </p>
        <p>
          10.3 - In all matters relating to account deletion, the decision of
          Dailydish.in shall be final and binding and by participating in the
          offer, the participants agree to be bound by the terms and conditions
          contained herein.
        </p>
        <br />
        <h6>SECTION 11 – DATA SECURITY PRECAUTIONS</h6>
        <p>
          We have in place appropriate technical and security measures to secure
          the information collected by us.
        </p>
        <p>
          We use vault and tokenization services from third party service
          providers to protect the sensitive personal information provided by
          you. The third-party service providers with respect to our vault and
          tokenization services and our payment gateway and payment processing
          are compliant with the payment card industry standard (generally
          referred to as PCI compliant service providers). You are advised not
          to send your full credit/debit card details through unencrypted
          electronic platforms. Where we have given you (or where you have
          chosen) a username and password which enables you to access certain
          parts of the Dailydish.in Platform, you are responsible for keeping
          these details confidential. We ask you not to share your password with
          anyone.
        </p>
        <p>
          Please we aware that the transmission of information via the internet
          is not completely secure. Although we will do our best to protect your
          personal data, we cannot guarantee the security of your data
          transmitted through the Dailydish.in Platform. Once we have received
          your information, we will use strict physical, electronic, and
          procedural safeguards to try to prevent unauthorised access.
        </p>
        <br />
        <h6>SECTION 12 – OPT-OUT</h6>
        <p>
          When you sign up for an account, you are opting in to receive emails
          and SMS from Dailydish.in. In order to “unsubscribe” please send out a
          mail to orders@dailydish.in, but note that you cannot opt out of
          receiving certain administrative notices, service notices, or legal
          notices from Dailydish.in.
        </p>
        <p>
          If you wish to withdraw your consent for the use and disclosure of
          your personal information in the manner provided in this Policy,
          please write to us at orders@dailydish.in. Please note that we may
          take time to process such requests, and your request shall take effect
          no later than 5 (Five) business days from the receipt of such request,
          after which we will not use your personal data for any processing
          unless required by us to comply with our legal obligations. We may not
          be able offer you any or all Services upon such withdrawal of your
          consent.
        </p>
        <br />
        <h6>SECTION 13 – COOKIES</h6>
        <p>
          Our Dailydish.in Platform and third parties with whom we partner, may
          use cookies, pixel tags, web beacons, mobile device IDs, “flash
          cookies” and similar files or technologies to collect and store
          information with respect to your use of the Services and third-party
          websites.
        </p>
        <p>
          Cookies are small files that are stored on your browser or device by
          websites, apps, online media and advertisements. We use cookies and
          similar technologies for purposes such as:
        </p>
        <ul>
          <li>Authenticating users.</li>
          <li>Remembering user preferences and settings.</li>
          <li>Determining the popularity of content.</li>
          <li>
            Delivering and measuring the effectiveness of advertising campaigns
          </li>
          <li>
            Analysing site traffic and trends, and generally understanding the
            online behaviours and interests of people who interact with our
            services.
          </li>
          <li>
            A pixel tag (also called a web beacon or clear GIF) is a tiny
            graphic with a unique identifier, embedded invisibly on a webpage
            (or an online ad or email), and is used to count or track things
            like activity on a webpage or ad impressions or clicks, as well as
            to access cookies stored on users’ computers. We use pixel tags to
            measure the popularity of our various pages, features and services.
            We also may include web beacons in e-mail messages or newsletters to
            determine whether the message has been opened and for other
            analytics.
          </li>
        </ul>
        <p>
          To modify your cookie settings, please visit your browser’s settings.
          By using our Services with your browser settings to accept cookies,
          you are consenting to our use of cookies in the manner described in
          this section.
        </p>
        <p>
          We may also allow third parties to provide audience measurement and
          analytics services for us, to serve advertisements on our behalf
          across the Internet, and to track and report on the performance of
          those advertisements. These entities may use cookies, web beacons,
          SDKs and other technologies to identify your device when you visit the
          Dailydish.in Platform and use our Services, as well as when you visit
          other online sites and service
        </p>
        <p>
          Please see our Cookie Policy for more information regarding the use of
          cookies and other technologies described in this section, including
          regarding your choices relating to such technologies.
        </p>
        <br />
        <h6>SECTION 14 – USES OF YOUR INFORMATION</h6>
        <p>
          We use the information we collect for following purposes, including:
        </p>
        <ul>
          <li>
            To provide, personalise, maintain and improve our products and
            services, such as to enable deliveries and other services, enable
            features to personalise your Dailydish.in account.
          </li>
          <li>
            To carry out our obligations arising from any contracts entered into
            between you and us and to provide you with the relevant information
            and services.
          </li>
          <li>
            To administer and enhance the security of our Dailydish.in Platform
            and for internal operations, including troubleshooting, data
            analysis, testing, research, statistical and survey purposes.
          </li>
          <li>
            To provide you with information about services we consider similar
            to those that you are already using, or have enquired about, or may
            interest you. If you are a registered user, we will contact you by
            electronic means (e-mail or SMS or telephone) with information about
            these services.
          </li>
          <li>
            To understand our users (what they do on our Services, what features
            they like, how they use them, etc.), improve the content and
            features of our Services (such as by personalizing content to your
            interests), process and complete your transactions, make special
            offers, provide customer support, process and respond to your
            queries.
          </li>
          <li>
            To generate and review reports and data about, and to conduct
            research on, our user base and Service usage patterns.
          </li>
          <li>
            To allow you to participate in interactive features of our Services,
            if any or
          </li>
          <li>
            To measure or understand the effectiveness of advertising we serve
            to you and others, and to deliver relevant advertising to you.
          </li>
          <li>
            If you are a partner restaurant or merchant or delivery partner, to
            track the progress of delivery or status of the order placed by our
            customers.
          </li>
          <li>to carry out academic research with academic partners.</li>
          <li>
            We may combine the information that we receive from third parties
            with the information you give to us and information we collect about
            you for the purposes set out above. Further, we may anonymize and/or
            de-identify information collected from you through the Services or
            via other means, including via the use of third-party web analytic
            tools. As a result, our use and disclosure of aggregated and/or
            de-identified information is not restricted by this Policy, and it
            may be used and disclosed to others without limitation.
          </li>
          <li>
            We analyse the log files of our Dailydish.in Platform that may
            contain Internet Protocol (IP) addresses, browser type and language,
            Internet service provider (ISP), referring, app crashes, page viewed
            and exit websites and applications, operating system, date/time
            stamp, and clickstream data. This helps us to administer the
            website, to learn about user behavior on the site, to improve our
            product and services, and to gather demographic information about
            our user base as a whole.
          </li>
        </ul>
        <br />
        <h6>SECTION 15 – YOUR CONSENT</h6>
        <p>
          By using the Dailydish.in Platform and the Services, you agree and
          consent to the collection, transfer, use, storage, disclosure and
          sharing of your information as described and collected by us in
          accordance with this Policy. If you do not agree with the Policy,
          please do not use or access the Dailydish.in Platform.
        </p>
        <br />
        <h6>SECTION 16 – POLICY CHANGES</h6>
        <p>
        We may occasionally update this Policy and such changes will be posted on this page. If we make any significant changes to this Policy we will endeavour to provide you with reasonable notice of such changes, such as via prominent notice on the Dailydish.in Platform or to your email address on record and where required by applicable law, we will obtain your consent. To the extent permitted under the applicable law, your continued use of our Services after we publish or send a notice about our changes to this Policy shall constitute your consent to the updated Policy.
        </p>
        <br />
        <h6>SECTION 17 – LINKS TO OTHER WEBSITES</h6>
        <p>
        The Dailydish.in Platform may contain links to other websites. Any personal information about you collected whilst visiting such websites is not governed by this Policy. Dailydish.in shall not be responsible for and has no control over the practices and content of any website accessed using the links contained on the Dailydish.in Platform. This Policy shall not apply to any information you may disclose to any of our service providers/service personnel which we do not require you to disclose to us or any of our service providers under this Policy.
        </p>
        <br />
        <h6>SECTION 18 – Grievance Redressal Mechanism</h6>
        <p>
        If you have any queries relating to the processing or usage of information provided by you in connection with this Policy, please email us at at order@dailydish.in  or write to our Grievance Officer at the following address
        </p>
        <br />
        <br />
        <p><b>Karthik</b></p>
        <p>Dailydish.in Grievance </p>
        <p>#6, 6th main , 4th cross, Bangalore </p>
        <br />
        <p><b>Email address:</b> grievance@dailydish.in</p>
        <p><b>Time:</b> Monday - Friday (09:00 a.m. to 18:00 p.m.)</p>
        <br />
        If you come across any abuse or violation of the Policy, please report to orders@dailydish.in
        <br />
        <br />
        <p>
            <b>please note, in compliance with The Information Technology Act, 2000 and the rules made thereunder, as well as The Consumer Protection Act 2019, and the rules made thereunder, the grievance redressal mechanism, including the contact details of the Grievance Officer are given herein above</b>
        </p>
        <p>
        Last updated on 26 October 2024
        </p>
      
      </div>
    </div>
  );
}

export default PrivacyPolicy;
